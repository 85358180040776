<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <!-- <el-form-item>
          <el-button type="success" @click="searchClick">查询</el-button>
        </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'banner_url'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.banner_url"
              style="width: 200px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="groupData.view_type == 'add' ? '新增分组' : '修改分组'"
      :visible.sync="groupVisible"
      v-if="groupVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="600px"
    >
      <el-form ref="bannerForm" :model="groupData" label-width="100px">
        <el-form-item
          label="分组名称"
          :rules="[
            {
              required: true,
              message: '请选择分组名称',
              trigger: ['blur', 'change'],
            },
          ]"
          prop="group_name"
        >
          <el-input
            maxlength="8"
            v-model="groupData.group_name"
            placeholder="请输入分组名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="门派选择">
          <el-select
            v-model="groupData.team_info"
            filterable
            remote
            multiple
            placeholder="请输入门派编号"
            :remote-method="remoteMethod"
            @change="handleSaveSect"
          >
            <el-option
              v-for="item in options"
              :key="item.sect_id"
              :label="item.sect_name"
              :value="item.sect_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveGroup">确定</el-button>
          <el-button @click="groupVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { MessageBox } from "element-ui";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      tempGroupData: [],
      options: [],
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {},
      dataTable: [],
      dataList: [
        {
          prop: "group_name",
          label: "分组名称",
          width: 300,
        },

        {
          prop: "sectInfo",
          label: "门派",
          width: 900,
          formatter: (row) => {
            const names = row.sectInfo.map((item) => item.sect_name).join(",");
            return names;
          },
        },
      ],
      groupVisible: false,
      groupData: {
        view_type: "add",
        _id: "",
        group_name: "",
        team_info: [],
      },
      match: false,
      match_id: "",
      matchType: "",
      season_id: "",
    };
  },
  methods: {
    handleSaveSect() {
      let arr = JSON.parse(JSON.stringify(this.tempGroupData));
      arr.push(this.options[0]);
      //去重
      this.tempGroupData = [
        ...new Set(arr.map((item) => JSON.stringify(item))),
      ].map((item) => JSON.parse(item));
    },
    async remoteMethod(query) {
      if (this.type_id == "wdl") {
        if ((query !== "" && query.length == 6) || query.length == 5) {
          this.getGruopSect(query);
        }
      } else {
        if (query !== "" && query.length == 6) {
          this.getGruopSect(query);
        }
      }
    },
    async getGruopSect(query) {
      let param = {
        match_id: this.match_id,
        sect_ids: query,
      };

      let { data, errorCode } = await this.$http.match.session_add_group(param);
      if (!data) {
        this.options = [];
      } else {
        let label = `${data[0].sect_name}`;

        this.options = [
          {
            sect_id: data[0].sect_id,
            sect_name: label,
          },
        ];
      }
    },
    //规则校验
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    // 添加初始化
    add() {
      this.groupData.view_type = "add";
      this.groupData._id = "";
      this.groupData.group_name = "";
      this.groupData.team_info = "";
      this.options = [];
      this.groupVisible = true;
      // this.$nextTick(() => {
      //   this.$refs["groupData"].clearValidate();
      // });
    },
    //编辑赋值
    handleEdit(row) {
      this.groupData.team_info = [];
      this.groupData.view_type = "edit";
      this.groupData._id = row._id;
      //分组名
      this.groupData.group_name = row.group_name;
      //拿到显示的参赛门派
      this.options = row.sectInfo;
      //拿到数组id
      if (row.sectInfo.length != 0) {
        row.sectInfo.forEach((item) => {
          this.groupData.team_info.push(item.sect_id);
        });
      }
      this.tempGroupData = row.sectInfo;
      this.groupVisible = true;
    },
    //添加banner
    saveGroup() {
      this.$refs.bannerForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          //对门派进行数据转化
          let result = [];
          if (this.groupData.team_info.length != 0) {
            this.groupData.team_info.forEach((item1) => {
              this.tempGroupData.forEach((item2) => {
                if (item1 == item2.sect_id) {
                  result.push({
                    sect_id: item2.sect_id,
                    sect_name: item2.sect_name,
                  });
                }
              });
            });
          }
          let saveData = {
            _id: this.groupData._id,
            group_name: this.groupData.group_name,
            team_info: result,
            match_id: this.match_id,
            season_id: this.season_id,
          };

          let { data, code } = await this.$http.match.save_season_group(
            saveData
          );
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.groupVisible = false;
            this.handleQuery();
          }
        }
      });
    },
    //删除
    async handleDelete(_id) {
      try {
        await MessageBox.confirm("是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        const { code } = await this.$http.match.delete_season_group({
          _id: _id,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查询按钮
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.match.season_group_list({
        pageNum,
        season_id: this.season_id,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async activated() {
    this.matchType = localStorage.getItem("matchType");
    this.type_id = this.$route.query.type_id;
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;

    //查询列表
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
